import type { TGate as TGate_0_0_1 } from './versions/v0.0.1'
import type { TGate as TGate_1_0_0 } from './versions/v1.0.0'
import type { TGate as TGate_1_1_0 } from './versions/v1.1.0'
import type { TGate as TGate_2_0_0 } from './versions/v2.0.0'
import type { TGate as TGate_2_1_0 } from './versions/v2.1.0'
import type { TGate as TGate_2_1_1 } from './versions/v2.1.1'
import type { TGate as TGate_2_1_2 } from './versions/v2.1.2'
import type { TGate as TGate_2_1_3 } from './versions/v2.1.3'
import type { TGate as TGate_2_1_4 } from './versions/v2.1.4'
import type { TGate as TGate_2_2_0 } from './versions/v2.2.0'
import type { TGate as TGate_2_2_1 } from './versions/v2.2.1'
import type { TGate as TGate_2_2_2 } from './versions/v2.2.2'
import type { TGate as TGate_2_2_3 } from './versions/v2.2.3'
import type { TGate as TGate_2_2_4 } from './versions/v2.2.4'
import type { TGate as TGate_2_2_5 } from './versions/v2.2.5'
import type { TGate as TGate_2_2_6 } from './versions/v2.2.6'

interface VersionToGatekeeper {
  /** @deprecated since v3.0.7 */
  'v0.0.1': TGate_0_0_1
  /** @deprecated since v3.0.7 */
  'v1.0.0': TGate_1_0_0
  /** @deprecated since v3.0.7 */
  'v1.1.0': TGate_1_1_0
  'v2.0.0': TGate_2_0_0
  'v2.1.0': TGate_2_1_0
  'v2.1.1': TGate_2_1_1
  'v2.1.2': TGate_2_1_2
  'v2.1.3': TGate_2_1_3
  'v2.1.4': TGate_2_1_4
  'v2.2.0': TGate_2_2_0
  'v2.2.1': TGate_2_2_1
  'v2.2.2': TGate_2_2_2
  'v2.2.3': TGate_2_2_3
  'v2.2.4': TGate_2_2_4
  'v2.2.5': TGate_2_2_5
  'v2.2.6': TGate_2_2_6
}
export type GatekeeperVersion = keyof VersionToGatekeeper
export type GatekeeperGetter = <T extends GatekeeperVersion>(
  version: T,
  name: string
) => VersionToGatekeeper[T]

export type TPersistedState = { toastReady: boolean }

declare global {
  interface Window {
    fabric: {
      getGatekeeper: GatekeeperGetter
      persistedState: TPersistedState
    }
    _smartico_user_id: string | null
    _smartico_language: string | null
  }
}
